import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Pagination from "../../../../shared/components/Pagination";
import useFetchClassDetails from "../../../../shared/api/classes/useFetchClassDetails";
import ClassNavigationBar from "../../../../shared/components/classes/ClassNavigationBar";
import StudentDetails from "../../../../shared/components/classes/classDetails/StudentDetails";
import useFetchClassStudents from "../../../../shared/api/classes/students/useFetchClassStudents";
import ModifyClassTeacher from "../../../../shared/components/classes/classDetails/ModifyClassTeacher";
import ClassStudentsTable from "../../../../shared/components/classes/classDetails/ClassStudentsTable";
import ClassDetailsOverview from "../../../../shared/components/classes/classDetails/ClassDetailsOverview";

const ClassDetails = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const studentId = query.get("student");
  const type = query.get("type");
  const action = query.get("action");

  const { classId, schoolId } = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(null);

  const [classLoading, classDetails, fetchClassDetails] =
    useFetchClassDetails(classId);
  const [loadingStudents, students] = useFetchClassStudents(
    classId,
    page,
    setPagination
  );

  function closeSideModal() {
    navigate(`/${schoolId}/classes/${classId}`);
  }

  return (
    <div>
      <div className="page">
        <h1 className="page_title mb-5">
          Class Details{" "}
          {classDetails
            ? `(${classDetails?.category} ${classDetails?.suffix})`
            : null}
        </h1>

        <ClassNavigationBar activeIndex={0} />

        <div className="main_content">
          <ClassDetailsOverview
            classDetails={classDetails}
            loading={classLoading}
          />

          <ClassStudentsTable
            data={students}
            loading={loadingStudents}
            studentId={studentId}
          />

          <Pagination setPage={setPage} pagination={pagination} />
        </div>
      </div>

      {studentId && type === "details" ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div className={`page_right_bar ${studentId || action ? "block" : ""}`}>
        <StudentDetails
          studentId={studentId}
          type={type}
          closeSideModal={closeSideModal}
        />

        <ModifyClassTeacher
          classDetails={classDetails}
          action={action}
          refresh={fetchClassDetails}
        />
      </div>
    </div>
  );
};

export default ClassDetails;
