import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { selectCalendar } from "../../../../redux/slices/school/selectors";

import PageLoader from "../../../../shared/components/PageLoader";
import useFetchClassDetails from "../../../../shared/api/classes/useFetchClassDetails";
import ClassNavigationBar from "../../../../shared/components/classes/ClassNavigationBar";
import NewStudentForm from "../../../../shared/components/classes/newStudent/NewStudentForm";
import useAddStudentToClass from "../../../../shared/api/classes/students/useAddStudentToClass";

const NewStudent = () => {
  const { classId, schoolId } = useParams();
  const [classLoading, classDetails] = useFetchClassDetails(classId);
  const currentCalendar = useSelector(selectCalendar);

  const [guardian, setGuardian] = useState(null);
  const [student, setStudent] = useState(null);
  const [registerStudentRequest, loading] = useAddStudentToClass();

  async function registerStudent(e) {
    e.preventDefault();
    student.parentContact = guardian.phone;
    student.address = guardian?.address;
    student.category = classDetails?.category;
    student.classId = classDetails?.classId;
    student.suffix = classDetails?.suffix;
    student.schoolId = schoolId;

    await registerStudentRequest({
      student,
      guardian,
      calendarId: currentCalendar?.calendarId,
    });
  }

  if (classLoading) {
    return <PageLoader loading={classLoading} />;
  }

  return (
    <div>
      <div className="page">
        <h1 className="page_title mb-5">
          Add Student{" "}
          {classDetails
            ? `(${classDetails?.category} ${classDetails?.suffix})`
            : null}
        </h1>

        <ClassNavigationBar activeIndex={1} />

        <div className="main_content">
          <NewStudentForm
            category={classDetails?.category}
            guardian={guardian}
            setGuardian={setGuardian}
            student={student}
            setStudent={setStudent}
            loading={loading}
            onSubmit={registerStudent}
          />
        </div>
      </div>
    </div>
  );
};

export default NewStudent;
