import {
  Sidebar as ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import { FiUsers } from "react-icons/fi";
import { BiLogOut } from "react-icons/bi";
import { useEffect, useState } from "react";
import { RxDashboard } from "react-icons/rx";
import { RiSchoolLine } from "react-icons/ri";
import { VscFolderOpened } from "react-icons/vsc";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import Logo from "./Logo";

const Sidebar = ({ toggleMenu }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("");
  // const totalUnreadNotif = useSelector(selectTotalUnreadNotifications);

  useEffect(() => {
    setActiveTab(location.pathname?.split("/"));
  }, [location, setActiveTab]);

  async function logout() {
    try {
      localStorage.removeItem("token");
      navigate("/login");
    } catch (error) {}
  }

  return (
    <ProSidebar
      className="h-full bg-white border-none"
      backgroundColor="white"
      width="230px"
    >
      <div className="px-8 py-5 mb-5 border-b ">
        <Logo />
      </div>

      <Menu
        menuItemStyles={{
          button: {
            [`&`]: {
              height: "40px",
            },
          },
        }}
        stye
      >
        <MenuItem
          icon={<RxDashboard />}
          component={<NavLink to={`/dashboard`} />}
          className={`${
            activeTab?.[1] === "dashboard"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Dashboard
        </MenuItem>

        <MenuItem
          icon={<RiSchoolLine />}
          component={<NavLink to={`/schools`} />}
          className={`${
            activeTab?.[1] === "schools" ? "sidebar_active" : "sidebar_inactive"
          }`}
          onClick={toggleMenu}
        >
          Schools
        </MenuItem>

        {/**
        |--------------------------------------------------
        | Users
        |--------------------------------------------------
        */}
        <SubMenu
          label={"Users"}
          className={`${
            activeTab?.[1] === "users" ? "sidebar_active" : "sidebar_inactive"
          }`}
          icon={<FiUsers />}
        >
          <MenuItem
            component={<NavLink to={`/users/teachers`} />}
            className={`${
              activeTab?.[2] === "teachers"
                ? "sidebar_active"
                : "sidebar_inactive"
            }`}
            onClick={toggleMenu}
          >
            Teachers
          </MenuItem>

          <MenuItem
            component={<NavLink to={`/users/students`} />}
            className={`${
              activeTab?.[2] === "students"
                ? "sidebar_active"
                : "sidebar_inactive"
            }`}
            onClick={toggleMenu}
          >
            Students
          </MenuItem>

          <MenuItem
            component={<NavLink to={`/users/school-owners`} />}
            className={`${
              activeTab?.[2] === "school-owners"
                ? "sidebar_active"
                : "sidebar_inactive"
            }`}
            onClick={toggleMenu}
          >
            School Owners
          </MenuItem>
        </SubMenu>

        {/**
        |--------------------------------------------------
        | Curriculums
        |--------------------------------------------------
        */}
        <SubMenu
          label={"Curriculums"}
          className={`${
            activeTab?.[1] === "curriculum"
              ? "sidebar_active"
              : "sidebar_inactive"
          }`}
          icon={<VscFolderOpened />}
        >
          <MenuItem
            component={<NavLink to={`/curriculum/calendar`} />}
            className={`${
              activeTab?.[2] === "calendar"
                ? "sidebar_active"
                : "sidebar_inactive"
            }`}
            onClick={toggleMenu}
          >
            Calendar
          </MenuItem>

          <MenuItem
            component={<NavLink to={`/curriculum/junior`} />}
            className={`${
              activeTab?.[2] === "junior"
                ? "sidebar_active"
                : "sidebar_inactive"
            }`}
            onClick={toggleMenu}
          >
            Junior Subjects
          </MenuItem>

          <MenuItem
            component={<NavLink to={`/curriculum/senior`} />}
            className={`${
              activeTab?.[2] === "senior"
                ? "sidebar_active"
                : "sidebar_inactive"
            }`}
            onClick={toggleMenu}
          >
            Senior Subjects
          </MenuItem>

          <MenuItem
            component={<NavLink to={`/curriculum/academic-sessions`} />}
            className={`${
              activeTab?.[2] === "academic-sessions"
                ? "sidebar_active"
                : "sidebar_inactive"
            }`}
            onClick={toggleMenu}
          >
            Academic Sessions
          </MenuItem>
        </SubMenu>

        <MenuItem
          onClick={logout}
          icon={<BiLogOut size={16} />}
          className="sidebar_inactive !text-coral_red"
        >
          Logout
        </MenuItem>
      </Menu>
    </ProSidebar>
  );
};
export default Sidebar;
