import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  selectCalendar,
  selectSchoolDetails,
} from "../../../../redux/slices/school/selectors";
import {
  getAverage,
  getScoreObtained,
} from "../../../../shared/utils/functions";

import Loader from "../../../../shared/components/Loader";
import PageLoader from "../../../../shared/components/PageLoader";
import EmptyTable from "../../../../shared/components/EmptyTable";
import useFetchCalendars from "../../../../shared/api/calendar/useFetchCalendars";
import useFetchClassDetails from "../../../../shared/api/classes/useFetchClassDetails";
import useFetchStudentBasicInfo from "../../../../shared/api/hooks/useFetchStudentBasicInfo";
import useFetchStudentReportCard from "../../../../shared/api/cards/useFetchStudentReportCard";
import NewScorecard from "../../../../shared/components/classes/studentReportCard/NewScorecard";
import NewReportCard from "../../../../shared/components/classes/studentReportCard/NewReportCard";
import PrincipalRemark from "../../../../shared/components/classes/studentReportCard/PrincipalRemark";
import DeleteScorecard from "../../../../shared/components/classes/studentReportCard/DeleteScorecard";
import UpdateStudentClass from "../../../../shared/components/classes/studentReportCard/UpdateStudentClass";
import StudentClassHistory from "../../../../shared/components/classes/studentReportCard/StudentClassHistory";
import useFetchStudentClassHistory from "../../../../shared/api/archives/students/useFetchStudentClassHistory";
import useUpdateStudentClassHistory from "../../../../shared/api/archives/students/useUpdateStudentClassHistory";
import ScorecardsByTermTableBody from "../../../../shared/components/classes/studentReportCard/ScorecardsByTermTableBody";
import ScorecardsByTermTableHeader from "../../../../shared/components/classes/studentReportCard/ScorecardsByTermTableHeader";
import ScorecardsByTermTableOverview from "../../../../shared/components/classes/studentReportCard/ScorecardsByTermTableOverview";
import StudentReportCardNavigatorBar from "../../../../shared/components/classes/studentReportCard/StudentReportCardNavigatorBar";

// TODO: Add remark preview to page, add grade coloring
const StudentReportCard = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const scorecardId = query.get("scorecardId");
  const action = query.get("action");
  const markStudentAs = query.get("markStudentAs");

  const navigate = useNavigate();
  const school = useSelector(selectSchoolDetails);
  const currentCalendar = useSelector(selectCalendar);
  const { classId, schoolId, studentId } = useParams();

  const [studentDetailsLoading, studentDetails] =
    useFetchStudentBasicInfo(studentId);

  const [classDetailsLoading, classsDetails] = useFetchClassDetails(classId);

  const [calendars, loadingCalendars] = useFetchCalendars(school?.state);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [reportCard, scorecards, reportCardLoading, fetchReportCard] =
    useFetchStudentReportCard(selectedCalendar);
  const [updateStudentClassRequest, updating] = useUpdateStudentClassHistory();
  const [classHistories, loadingClassHistories, fetchClassHistory] =
    useFetchStudentClassHistory();

  useEffect(() => {
    setSelectedCalendar(currentCalendar);
  }, [currentCalendar]);

  useEffect(() => {
    if (reportCard) {
      fetchClassHistory({
        studentId: reportCard?.studentId,
        classId: reportCard?.classId,
        session: reportCard?.session,
      });
    }
  }, [reportCard, fetchClassHistory]);

  async function updateStudentClassHistory() {
    const payload = {
      status: markStudentAs,
      studentClassHistoryId: classHistories?.[0]?.studentClassHistoryId,
      classId,
      studentId,
    };
    if (markStudentAs === "left") payload.term = currentCalendar?.term;

    const isSuccesful = await updateStudentClassRequest(payload);
    if (isSuccesful) {
      navigate(`/${schoolId}/classes/${classId}/reportcards`);
    }
  }

  // calculate each term data
  const firstTermObtainableScore = scorecards?.length * 100;
  const firstTermScoreObtained = getScoreObtained(scorecards);

  function closeSideModal() {
    navigate(
      `/${schoolId}/classes/${classId}/students/${studentId}/reportcard`
    );
  }

  function handleDeleteScorecard(scorecard) {
    navigate(
      `/${schoolId}/classes/${classId}/students/${studentId}/reportcard?scorecardId=${scorecard?.scorecardId}&action=delete`
    );
  }

  function handleCancelNewReportcard() {
    navigate(
      `/${schoolId}/classes/${classId}/students/${studentId}/reportcard`
    );
  }

  function getPreviousTermSubjects() {}

  function handleSelectCalendar(params) {
    const [session, termString] = params?.split(" - ");
    const term = termString?.split(" ")?.[0];

    const found = calendars?.find(
      (el) => el?.session === session && el?.term === term
    );

    setSelectedCalendar(found);
  }

  if (studentDetailsLoading || classDetailsLoading)
    return (
      <PageLoader loading={studentDetailsLoading || classDetailsLoading} />
    );

  return (
    <div>
      <div className="page">
        <h1 className="page_title mb-5 capitalize">
          {`${studentDetails?.lastName || ""} ${
            studentDetails?.firstName || ""
          }'s`}{" "}
          Report Card
        </h1>

        <StudentReportCardNavigatorBar
          action={action}
          reportCard={reportCard}
          reportCardLoading={reportCardLoading}
        />

        {!reportCardLoading ? (
          <>
            <div className="main_content !pb-3">
              <ScorecardsByTermTableOverview
                obtainableScore={firstTermObtainableScore}
                obtainedScore={firstTermScoreObtained}
                average={`${getAverage(
                  firstTermScoreObtained,
                  firstTermObtainableScore
                )}%`}
                calendars={calendars}
                loadingCalendars={loadingCalendars}
                reportCard={reportCard}
                selectedCalendar={selectedCalendar}
                handleSelectCalendar={handleSelectCalendar}
              />

              <div className="table_wrapper">
                <div className="min-w-[800px]">
                  <ScorecardsByTermTableHeader />

                  {reportCardLoading ? (
                    <Loader loading={reportCardLoading} />
                  ) : null}

                  {!reportCardLoading && !reportCard ? (
                    <div className="main_content">
                      <EmptyTable
                        message={"No report card record found"}
                        onClickMessage={"Create Report Card"}
                        onClick={() =>
                          navigate(
                            `/${schoolId}/classes/${classId}/students/${studentId}/reportcard?action=new-reportcard`
                          )
                        }
                      />
                    </div>
                  ) : (
                    <ScorecardsByTermTableBody
                      data={scorecards}
                      handleDeleteScorecard={handleDeleteScorecard}
                      scorecardId={scorecardId}
                    />
                  )}
                </div>
              </div>
            </div>

            <StudentClassHistory
              reportCard={reportCard}
              classHistory={classHistories?.[0]}
              isGraduatingStudent={
                reportCard?.category === "JSS3" ||
                reportCard?.category === "SSS3"
              }
              loadingClassHistories={loadingClassHistories}
            />
          </>
        ) : null}
      </div>

      {action || markStudentAs ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div
        className={`page_right_bar ${action || markStudentAs ? "block" : ""}`}
      >
        <NewReportCard
          studentId={studentId}
          closeSideModal={closeSideModal}
          student={studentDetails}
          category={classsDetails?.category}
          fetchReportCard={fetchReportCard}
          action={action}
          handleCancelNewReportcard={handleCancelNewReportcard}
          previousSubjects={getPreviousTermSubjects()}
        />

        <DeleteScorecard
          action={action}
          scorecardId={scorecardId}
          handleCancelDeleteScorecard={closeSideModal}
          fetchReportCard={fetchReportCard}
        />

        <NewScorecard
          action={action}
          fetchReportCard={fetchReportCard}
          reportCard={reportCard}
          handleCancelNewScorecard={closeSideModal}
        />

        <PrincipalRemark
          action={action}
          reportCard={reportCard}
          fetchReportCard={fetchReportCard}
          closeSideModal={closeSideModal}
        />

        <UpdateStudentClass
          markStudentAs={markStudentAs}
          studentName={`${studentDetails?.lastName || ""} ${
            studentDetails?.firstName || ""
          }'s`}
          updating={updating}
          handleCancel={closeSideModal}
          handleUpdate={updateStudentClassHistory}
          currentCalendar={currentCalendar}
        />
      </div>
    </div>
  );
};

export default StudentReportCard;
