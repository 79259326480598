import { useState } from "react";
import { useParams } from "react-router-dom";

import useFetchClassDetails from "../../../../shared/api/classes/useFetchClassDetails";
import ClassNavigationBar from "../../../../shared/components/classes/ClassNavigationBar";
import SubjectTable from "../../../../shared/components/classes/classSubjects/SubjectTable";
import useFetchClassSubjects from "../../../../shared/api/classes/subjects/useFetchClassSubjects";
import SubjectOverview from "../../../../shared/components/classes/classSubjects/SubjectOverview";
import ModifyClassSubject from "../../../../shared/components/classes/classSubjects/ModifyClassSubject";

const ClassSubjects = () => {
  const { classId } = useParams();

  const [classLoading, classDetails] = useFetchClassDetails(classId);
  const [subjectLoading, subjects, fetchSubjects] =
    useFetchClassSubjects(classId);

  const [selectedSubject, setSelectedSubject] = useState(null);

  function closeSideModal() {
    setSelectedSubject(null);
  }

  return (
    <div>
      <div className="page">
        <h1 className="page_title mb-5">
          Class Subjects{" "}
          {classDetails
            ? `(${classDetails?.category} ${classDetails?.suffix})`
            : null}
        </h1>

        <ClassNavigationBar activeIndex={2} />

        <div className="main_content">
          <SubjectOverview
            classDetails={classDetails}
            classLoading={classLoading}
          />

          <SubjectTable
            loading={subjectLoading}
            data={subjects}
            handleSelectSubject={setSelectedSubject}
            selectedSubject={selectedSubject}
          />
        </div>
      </div>

      {selectedSubject ? (
        <div className="page_dark_overlay" onClick={closeSideModal} />
      ) : null}

      <div className={`page_right_bar ${selectedSubject ? "block" : ""}`}>
        <ModifyClassSubject
          selectedSubject={selectedSubject}
          setSelectedSubject={setSelectedSubject}
          fetchSubjects={fetchSubjects}
        />
      </div>
    </div>
  );
};

export default ClassSubjects;
